<template>
  <div>
    <FirstSection />
    <div class="mx-2">
      <ProfileChart :widgets="widgets" />
      <ProfileTable :items="recentRecomms" />
    </div>
  </div>
</template>

<script>
import FirstSection from "./FirstSection.vue";
import ProfileChart from "./ProfileChart.vue";
import ProfileTable from "./ProfileTable.vue";
import { ref, onMounted, inject } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue';

export default {
  components: {
    FirstSection,
    ProfileChart,
    ProfileTable,
  },
  setup(props) {
    const $http = inject("$http");
    const recentRecomms = ref([]);
    const widgets = ref([]);
    const getDashboardData = async () => {
      await $http
        .get(`/advisor/dashboard`)
        .then((response) => {
            recentRecomms.value = response.data.recentRecomms;
            widgets.value = response.data.widgets;
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    onMounted(async () => {
      await getDashboardData();
    });
    return {
      recentRecomms,
      widgets,
    };
  },
};
</script>