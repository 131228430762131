<template>
  <div>
    <b-row class="">
      <b-col lg="4" sm="6">
        <vue-apex-charts
          ref="chart1Ref"
          type="bar"
          height="315"
          class="chart-profile"
          :options="chart1.chartOptions"
          :series="chart1.series"
        />
      </b-col>
      <b-col lg="4" sm="6" class="">
        <vue-apex-charts
          ref="chart2Ref"
          type="bar"
          height="315"
          class="chart-profile"
          :options="chart2.chartOptions"
          :series="chart2.series"
        />
      </b-col>
      <b-col lg="4" sm="6" class=" ">
        <vue-apex-charts
          ref="chart3Ref"
          type="bar"
          height="315"
          class="chart-profile"
          :options="chart3.chartOptions"
          :series="chart3.series"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { onMounted, watch, ref } from "@vue/composition-api";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    widgets: {
      type: Array,
      default: () => {
        return []
      }
    },
  },

  setup(props) {
    const chart1Ref = ref(null);
    const chart2Ref = ref(null);
    const chart3Ref = ref(null);
    const chart1 = ref({
      series: [
        {
          name: "Client",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          toolbar: {
            show: false,
          },
        },
        colors: ["#005DB3"],
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
          hover: {
            sizeOffset: 4,
          },
        },
        xaxis: {
          title: {
            text: "",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        title: {
          text: '',
          align: 'left'
        },
      },
    });

    const chart2 = ref({
      series: [
        {
          name: "Revenue",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          group: "social",
          height: 350,
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#005DB3"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          title: {
            text: "",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        title: {
          text: '',
          align: 'left'
        },
      },
    });

    const chart3 = {
      series: [
        {
          name: "Recommendation Trend",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        colors: ["#005DB3"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 5,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          title: {
            text: "",
          },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        tooltip: {
          x: {
            format: "yyyy",
          },
          fixed: {
            enabled: false,
            position: "topRight",
          },
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30,
            },
          },
          padding: {
            left: 20,
          },
        },
        title: {
          text: '',
          align: 'left'
        },
      },
    };
    const updateChart = async (data, widget) => {
      data.value.updateOptions({
        xaxis: {
          title: {
            text: widget.xLable,
          },
        },
        yaxis: {
          title: {
            text: widget.yLable,
          },
        },
        title: {
          text:  widget.yLable,
          align: 'left'
        },
      });
      const dataList = widget.dataList.map((el) => {
        return { x: el.yVal, y: parseInt(el.xVal) };
      });
      data.value.updateSeries([{ data: dataList }]);
    };

    watch(props, val => {
      if(props.widgets.length) {
        props.widgets.forEach(async element => {
          if(element.seq == 0) await updateChart(chart1Ref, element);
          if(element.seq == 1) await updateChart(chart2Ref, element);
          if(element.seq == 2) await updateChart(chart3Ref, element);
        });
      }
    }, { deep: true });
    onMounted(async () => {
    });
    return {
      chart1,
      chart2,
      chart3,
      chart1Ref,
      chart2Ref,
      chart3Ref,
    };
  },
};
</script>
