<template>
  <b-row>
    <b-col cols="12">
      <div class="card mt-5 table-card">
        <b-card>
          <b-card-header class="h1">
            <b-card-title class="font-weight-bolder h1"
              >Recent Recommendation</b-card-title
            >
          </b-card-header>

          <b-table
            sticky-header
            responsive
            class="position-relative b-table-sticky-table"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-direction="asc"
            show-empty
            @sort-changed="sortChanged"
            :per-page="parPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="[
              'date',
              'symbol',
              'rating',
              'entryPrice',
              'targetPrice',
              'status',
            ]"
          >
            <!-- Loader -->
            <template #table-busy>
              <div class="position-relative load-data py-2">
                <!-- <loader /> -->
              </div>
            </template>

            <!-- empty data -->
            <template #empty>
              <p class="text-center pt-1">Data Not found.</p>
            </template>

             <!-- Column: createdAt -->
            <template #cell(createdAt)="data">
              {{ getDate(data.item.createdAt) }}
            </template>

            <template #cell(callType)="data">
              <b-badge
                pill
                :variant="
                  data.item.callType == 'buy' ? 'light-success' : 'light-danger'
                "
                class="text-capitalize active-status"
              >
                {{ data.item.callType }}
              </b-badge>
            </template>

            <template #cell(entryPrice)="data">
              {{ data.item.entryPrice.toFixed(2) }}
            </template>

            <template #cell(targetPrice)="data">
              {{ data.item.targetPrice.toFixed(2) }}
            </template>

            <template #cell(gainPercent)="data">
              {{data.item.gainPercent ? `${data.item.gainPercent}%` : '-'}}
            </template>

            <template #cell(status)="data">
              <b-badge
                pill
                :variant="
                  data.item.status == 'created' ? 'light-primary' : data.item.status == 'target_hit' ? 'light-success' : data.item.status == 'target_miss' ? 'light-danger' : data.item.status == 'target_open' ? 'light-primary' : 'light-secondary'
                "
                class="text-capitalize active-status"
              >
                {{ data.item.status == 'created' ? 'Unknown' : data.item.status == 'target_hit' ? 'Success' : data.item.status == 'target_miss' ? 'Missed' : data.item.status == 'target_open' ? 'Open' : "null" }}
              </b-badge>
            </template>

            <!-- Column: deatils -->
            <template #cell(details)="data">
              <b-button
                @click="recommendationData = data.item"
                v-b-modal.modal-view-details
                variant="outline-primary rounded-pill"
              >
                View
              </b-button>
              <b-button size="md" v-if="data.item.status == 'target_open'" @click="recommendationData = data.item" variant="outline-primary rounded-pill" class="ml-1" v-b-modal.modal-update-status> Update </b-button>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-end flex-wrap pt-0">
            <!-- pagination -->
            <div class="w-100">
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="parPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
              <template #prev-text>
                <feather-icon icon="ArrowLeftIcon" size="18" class="mr-1" /> Previous
              </template>
              <template #next-text>
                <span>Next</span> <feather-icon icon="ArrowRightIcon" size="18" class="ml-1"/> 
              </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>

        <recommendation-view-model v-if="recommendationData" :recommendationData="recommendationData" />
        <recommendation-update-model v-if="recommendationData" :recommendationData="recommendationData" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable global-require */
import {  ref } from "@vue/composition-api";
import store from "@/store";
import moment from "moment";
import RecommendationViewModel from "../RecommendationViewModel.vue";
import RecommendationUpdateModel from "../RecommendationUpdateModel.vue";

export default {
  components: {
    RecommendationViewModel,
    RecommendationUpdateModel
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  setup() {
    const fields = [
      {
        key: "createdAt",
        label: " Date",
        sortable: true,
      },
      {
        key: "symbol",
        label: "Symbol",
        sortable: true,
      },
      {
        key: "callType",
        label: "Rating",
        sortable: true,
      },
      {
        key: "entryPrice",
        label: "Entry Price",
        sortable: true,
        class: "",
      },
      {
        key: "targetPrice",
        label: "Target Price",
        sortable: true,
      },
      {
        key: "gainPercent",
        label: "Gain",
        sortable: true,
      },
      {
        key: "status",
        label: "Status",
        sortable: true,
      },
      {
        key: "details",
        label: "Details",
      },
    ];
    const parPage = 15;
    const currentPage = ref(1);
    const sortByAscDesc = ref("asc");
    const sortBy = ref("");
    const filter = ref("");
    const sortDesc = ref(false);

    const recommendationData = ref(null);

    const sortChanged = (e) => {
      sortBy.value = e.sortBy;
      if (e.sortDesc == true) {
        sortByAscDesc.value = "desc";
      } else {
        sortByAscDesc.value = "asc";
      }
    };
    const getDate = (val) => {
      const date =new Date(val * 1000);
      return moment(date).format('DD-MM-YYYY')
    }

    const daysCalculator = (days) => {
      const years = Math.floor(days / 365);
      const remainingDays = days % 365;
      const months = Math.floor(remainingDays / 30);
      const remainingDaysAfterMonths = remainingDays % 30;
      let output = '';
      if (years > 0) output += ` ${years} year`;
      if (months > 0) output += ` ${months} month`;
      if (remainingDaysAfterMonths > 0) output += ` ${remainingDaysAfterMonths} days`;
      return output;
    }

    return {
      sortByAscDesc,
      sortDesc,
      sortChanged,
      fields,
      filter,
      currentPage,
      parPage,
      sortBy,
      getDate,
      daysCalculator,
      recommendationData
    };
  },
};
/* eslint-disable global-require */
</script>