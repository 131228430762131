<template>
  <div>
    <b-row class="m-0 main-section mb-1">
      <b-col cols="12">
        <div class="d-block d-md-flex justify-content-between align-items-center">
          <div class="mb-2 m-md-0">
            <h2>Analytical Overview</h2>
            <p class="mb-0">Information about your current plan and usages</p>
          </div>
          <div class="d-block d-sm-flex align-items-center">
            <b-button class="profile-btn mb-2 my-sm-0" variant="primary" v-b-modal.modal-no-backdrop>
              <feather-icon
                class="mr-1"
                icon="PlusIcon"
                fill="#fff"
                stroke="#fff"
                size="18"
              />
              Create Recommendation
            </b-button>
            <!-- <DatePicker
              v-model="date"
              class="month-range"
              placeholder="Select Month"
              :disabled-date="(date) => date >= new Date()"
              type="month"
              range
              range-separator="  -  "
              @change="changeRange"
            /> -->
          </div>
        </div>
      </b-col>
    </b-row>
    <recommendation-model />
  </div>
</template>
<script>
import { ref, computed } from "@vue/composition-api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import RecommendationModel from '../RecommendationModel.vue'

export default {
  components: {
    DatePicker,
    RecommendationModel
  },
  setup() {
    const date = ref([null, null]);
    const monthDifference = computed(() => {
      const [start, end] = date.value;
      if (start && end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        return months + endDate.getMonth() - startDate.getMonth();
      }
      return 0;
    });
    const disabledMonth = ref(null);
    const changeRange = (value) => {
      if (monthDifference.value > 2) {
        date.value = [value[0], moment(new Date(value[0])).add(2, "M")?._d];
      }
    };
    return {
      changeRange,
      date,
      disabledMonth,
    };
  },
};
</script>

<style lang="scss" >
.month-range {
  width: 300px;
  margin-left: 20px;
  height: 40px;
}
.month-range input,
.mx-input-wrapper {
  height: 100% !important;
}
.month-range .mx-input {
  background: transparent !important;
  border: 1px solid #414754;
}
</style>
